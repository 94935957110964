import quotes from "../quotes.json"
<let/quote />
<const/rollQuote() {
	const random = <Type,>(arr: readonly Type[]): Type =>
		arr[Math.floor(Math.random() * arr.length)]

	quote = random(quotes.filter((q) => q !== quote))
} />
<lifecycle onMount=rollQuote />
<legend role="none">
	<span role="heading" aria-level="2">Philosophy</span>
	${" "}
	<button onClick=rollQuote>🎲 Another quote</button>
</legend>
<figure>
	<blockquote>
		<noscript>JavaScript is required to view my insights</noscript>${quote}
	</blockquote>
	<figcaption>―Jack Wavesmiley</figcaption>
</figure>

<style>
	@import "@fontsource/dancing-script/latin-400.css";

	@media (prefers-color-scheme: light) {
		#philosophy legend span {
			background: Canvas;
			color: CanvasText;
		}
	}
	#philosophy {
		align-content: center;
		background: #000 url(./beach.webp) center/cover;
		image-rendering: pixelated;
		color: #fff;
	}
	#philosophy figure {
		font-family: "Dancing Script", cursive;
		margin: 0;
		padding: 2em;
	}
	#philosophy blockquote {
		text-align: center;
		filter: drop-shadow(0 0 5px);
		margin: 0;
		display: block;
		font-size: xx-large;
	}
	#philosophy figcaption {
		text-align: right;
	}
	@media (scripting: none) or (scripting: initial-only) {
		#philosophy button {
			display: none;
		}
	}
</style>
